import { Injectable } from '@angular/core';
import { ApiSite } from '../../../utils/buildSiteFromApiResult';

export type DiagItem = { status: 'info' | 'warn' | 'error'; message: string };

export type Diag = {
    bobSite: DiagItem[];
    ovkSetup: DiagItem[];
    ovkPartner: DiagItem[];
};

@Injectable()
export class BoxDiagService {
    private diag: Diag;

    private resetDiag() {
        this.diag = {
            bobSite: [],
            ovkSetup: [],
            ovkPartner: [],
        };
    }

    process(bobSite: ApiSite, ovkSetup, ovkPartner): Diag {
        this.resetDiag();

        this.diagBob(bobSite);
        this.diagOvk(ovkSetup, ovkPartner);

        return this.diag;
    }

    private diagBob(bobSite) {
        if (bobSite) {
            this.diag.bobSite.push({
                status: 'info',
                message: 'Installation trouvée',
            });
        } else if (!bobSite) {
            this.diag.bobSite.push({
                status: 'error',
                message: 'Installation introuvable',
            });
        }
    }

    private diagOvk(ovkSetup, ovkPartner) {
        if (ovkSetup) {
            this.diag.ovkSetup.push({ status: 'info', message: 'Setup trouvé' });
        } else {
            this.diag.ovkSetup.push({
                status: 'warn',
                message: 'Aucun setup trouvé',
            });
        }

        if (ovkPartner?.associationType === 'SERVEGO') {
            this.diag.ovkPartner.push({
                status: 'info',
                message: 'Abonnement déclaré',
            });
        } else {
            this.diag.ovkPartner.push({
                status: 'error',
                message: 'Abonnement manquant : ajout requis pour le suivi des évènements.',
            });
        }
    }
}
