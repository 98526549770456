import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GatewayId } from '../../../site-detail/models/Gateway';
import { ApiSite } from '../../../utils/buildSiteFromApiResult';
import { AdminHttpService } from '../../services/http.service';

@Injectable()
export class DebugApi {
    constructor(private adminHttpService: AdminHttpService) {}

    getBobSite(gatewayId: GatewayId): Observable<ApiSite> {
        return this.adminHttpService.get(`/debug/bob/${gatewayId}`, {
            prefix: false,
        });
    }

    getOvkSetup(gatewayId: GatewayId): Observable<any> {
        return this.adminHttpService.get(`/debug/ovk/${gatewayId}`, {
            prefix: false,
        });
    }

    getOvkPartner(gatewayId: GatewayId): Observable<any> {
        return this.adminHttpService.get(`/debug/ovk/${gatewayId}/partner`, {
            prefix: false,
        });
    }
}
